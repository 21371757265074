@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
@function theme-color($key: "primary") {
  @return map-get($theme-colors, $key);
}

$primary-gradient: linear-gradient(90deg, #8853E3 0%, #4D74D9 100%);
$blue-gradient: linear-gradient(90deg, #6188FF 0%, #2450D6 100%);
$red-gradient: linear-gradient(90deg, #D62424 0%, #FF6161 100%);
$black-gradient: linear-gradient(90deg, #343434 0%, #222222 100%);
$green-gradient: linear-gradient(90deg, #24CB66 0%, #61FFA0 100%);

// Brand Colors
$blue: #4D74D9;
$indigo: #8855E3;
$purple: #583694;
$pink: #e83e8c;
$red: #ec4145;
$orange: #fd7e14;
$yellow: #f9a62b;
$green: #3ee074;
$teal: #20c997;
$cyan: #38aee6;
$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #99aab5;
$gray-700: #495057;
$gray-800: #23272a;
$gray-900: #212529;
$black: #000;
$grays: (100: $gray-100, 200: $gray-200, 300: $gray-300, 400: $gray-400, 500: $gray-500, 600: $gray-600, 700: $gray-700, 800: $gray-800, 900: $gray-900);
$theme-colors: (primary: $blue, secondary: $gray-600, success: $green, info: $cyan, warning: $yellow, danger: $red, light: $gray-100, dark: $gray-800);
$colors: (blue: $blue, indigo: $indigo, purple: $purple, pink: $pink, red: $red, orange: $orange, yellow: $yellow, green: $green, teal: $teal, cyan: $cyan, white: $white, gray: $gray-600,gray-dark: $gray-800);
$theme-color-interval: 8%;


// Spacing
$spacer: 1rem;
$spacers: (0: 0, 1: ($spacer * 0.25), 2: ($spacer * 0.5), 3: $spacer, 4: ($spacer * 1.5), 5: ($spacer * 3));
$sizes: (25: 25%, 50: 50%, 75: 75%, 100: 100%);

// Components
$line-height-lg: 1.5;
$line-height-sm: 1.5;
$border-width: 1px;
$border-radius: 0.25rem;
$border-radius-lg: 0.3rem;
$border-radius-sm: 0.2rem;
$component-active-color: $white;
$component-active-bg: theme-color('primary');
$caret-width: 0.3em;
$transition-base: all 0.2s ease-in-out;
$transition-fade: opacity 0.15s linear;
$transition-collapse: height 0.35s ease;

// Options
$enable-rounded: true;
$enable-shadows: false;
$enable-gradients: false;
$enable-transitions: true;
$enable-hover-media-query: false;
$enable-grid-classes: true;
$enable-print-styles: true;


// Body
$body-bg: $white;
$body-color: $gray-900;


// Links
$link-color: theme-color('primary');
$link-decoration: none;
$link-hover-color: darken($link-color, 15%);
$link-hover-decoration: underline;


// Fonts
$font-family-sans-serif: 'Roboto Condensed', sans-serif;
$font-family-monospace: Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
$font-family-base: $font-family-sans-serif;
$font-size-base: 1rem;
$font-size-lg: 1.25rem;
$font-size-sm: 0.875rem;
$font-weight-normal: normal;
$font-weight-bold: bold;
$font-weight-base: $font-weight-normal;
$line-height-base: 1.5;
$h1-font-size: 2.5rem;
$h2-font-size: 2rem;
$h3-font-size: 1.75rem;
$h4-font-size: 1.5rem;
$h5-font-size: 1.25rem;
$h6-font-size: 1rem;
$headings-margin-bottom: ($spacer / 2);
$headings-font-family: 'Montserrat', sans-serif;
$headings-font-weight: 700;
$headings-line-height: 1.1;
$headings-color: #454545;
$display1-size: 6rem;
$display2-size: 5.5rem;
$display3-size: 4.5rem;
$display4-size: 3.5rem;
$display1-weight: 300;
$display2-weight: 300;
$display3-weight: 300;
$display4-weight: 300;
$display-line-height: $headings-line-height;
$lead-font-size: 1.25rem;
$lead-font-weight: 300;
$small-font-size: 80%;
$text-muted: $gray-600;
$blockquote-small-color: $gray-600;
$blockquote-font-size: ($font-size-base * 1.25);
$hr-border-color: rgba($black, 0.1);
$hr-border-width: $border-width;
$mark-padding: 0.2em;
$dt-font-weight: $font-weight-bold;
$kbd-box-shadow: inset 0 -0.1rem 0 rgba($black, 0.25);
$nested-kbd-font-weight: $font-weight-bold;
$mark-bg: #fcf8e3;


// Buttons
$input-btn-padding-y: 0.5rem;
$input-btn-padding-x: 0.75rem;
$input-btn-line-height: 1.25;
$input-btn-padding-y-sm: 0.25rem;
$input-btn-padding-x-sm: 0.5rem;
$input-btn-line-height-sm: 1.5;
$input-btn-padding-y-lg: 0.5rem;
$input-btn-padding-x-lg: 1rem;
$input-btn-line-height-lg: 1.5;
$btn-font-weight: $font-weight-normal;
$btn-box-shadow: inset 0 1px 0 rgba($white, 0.15), 0 1px 1px rgba($black, 0.075);
$btn-focus-box-shadow: 0 0 0 3px rgba(theme-color('primary'), 0.25);
$btn-active-box-shadow: inset 0 3px 5px rgba($black, 0.125);
$btn-link-disabled-color: $gray-600;
$btn-block-spacing-y: 0.5rem;
$btn-border-radius: 10em;
$btn-border-radius-lg: 10em;
$btn-border-radius-sm: 10em;
$btn-transition: all 0.15s ease-in-out;


// Forms
$input-bg: $white;
$input-disabled-bg: $gray-200;
$input-color: $gray-700;
$input-border-color: rgba($black, 0.15);
$input-btn-border-width: $border-width;
$input-box-shadow: inset 0 1px 1px rgba($black, 0.075);
$input-border-radius: 10em;
$input-border-radius-lg: 10em;
$input-border-radius-sm: 10em;
$input-focus-bg: $input-bg;
$input-focus-border-color: lighten(theme-color('primary'), 25%);
$input-focus-box-shadow: $input-box-shadow, $btn-focus-box-shadow;
$input-focus-color: $input-color;
$input-placeholder-color: $gray-600;
$input-height-border: $input-btn-border-width * 2;
$input-height-inner: ($font-size-base * $input-btn-line-height) + ($input-btn-padding-y * 2);
$input-height: calc(#{$input-height-inner} + #{$input-height-border});
$input-height-inner-sm: ($font-size-sm * $input-btn-line-height-sm) + ($input-btn-padding-y-sm * 2);
$input-height-sm: calc(#{$input-height-inner-sm} + #{$input-height-border});
$input-height-inner-lg: ($font-size-sm * $input-btn-line-height-lg) + ($input-btn-padding-y-lg * 2);
$input-height-lg: calc(#{$input-height-inner-lg} + #{$input-height-border});
$input-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
$form-text-margin-top: 0.25rem;
$form-check-margin-bottom: 0.5rem;
$form-check-input-gutter: 1.25rem;
$form-check-input-margin-y: 0.25rem;
$form-check-input-margin-x: 0.25rem;
$form-check-inline-margin-x: 0.75rem;
$form-group-margin-bottom: 1rem;
$input-group-addon-bg: $gray-200;
$input-group-addon-border-color: $input-border-color;
$custom-control-gutter: 1.5rem;
$custom-control-spacer-y: 0.25rem;
$custom-control-spacer-x: 1rem;
$custom-control-indicator-size: 1rem;
$custom-control-indicator-bg: #ddd;
$custom-control-indicator-bg-size: 50% 50%;
$custom-control-indicator-box-shadow: inset 0 0.25rem 0.25rem rgba($black, 0.1);
$custom-control-indicator-disabled-bg: $gray-200;
$custom-control-description-disabled-color: $gray-600;
$custom-control-indicator-checked-color: $white;
$custom-control-indicator-checked-bg: theme-color('primary');
$custom-control-indicator-checked-box-shadow: none;
$custom-control-indicator-focus-box-shadow: 0 0 0 1px $body-bg, 0 0 0 3px theme-color('primary');
$custom-control-indicator-active-color: $white;
$custom-control-indicator-active-bg: lighten(theme-color('primary'), 35%);
$custom-control-indicator-active-box-shadow: none;
$custom-checkbox-indicator-border-radius: $border-radius;
$custom-checkbox-indicator-indeterminate-bg: theme-color('primary');
$custom-checkbox-indicator-indeterminate-color: $custom-control-indicator-checked-color;
$custom-checkbox-indicator-indeterminate-box-shadow: none;
$custom-radio-indicator-border-radius: 50%;
$custom-select-padding-y: 0.375rem;
$custom-select-padding-x: 0.75rem;
$custom-select-height: $input-height;
$custom-select-indicator-padding: 1rem;
$custom-select-line-height: $input-btn-line-height;
$custom-select-color: $input-color;
$custom-select-disabled-color: $gray-600;
$custom-select-bg: $white;
$custom-select-disabled-bg: $gray-200;
$custom-select-indicator-color: #333;
$custom-select-border-width: $input-btn-border-width;
$custom-select-border-color: $input-border-color;
$custom-select-border-radius: $border-radius;
$custom-select-focus-border-color: lighten(theme-color('primary'), 25%);
$custom-select-focus-box-shadow: inset 0 1px 2px rgba($black, 0.075), 0 0 5px rgba($custom-select-focus-border-color, 0.5);
$custom-select-font-size-sm: 75%;
$custom-select-height-sm: $input-height-sm;
$custom-file-height: 2.5rem;
$custom-file-width: 14rem;
$custom-file-focus-box-shadow: 0 0 0 0.075rem $white, 0 0 0 0.2rem theme-color('primary');
$custom-file-padding-y: 1rem;
$custom-file-padding-x: 0.5rem;
$custom-file-line-height: 1.5;
$custom-file-color: $gray-700;
$custom-file-bg: $white;
$custom-file-border-width: $border-width;
$custom-file-border-color: $input-border-color;
$custom-file-border-radius: $border-radius;
$custom-file-box-shadow: inset 0 0.2rem 0.4rem rgba($black, 0.05);
$custom-file-button-color: $custom-file-color;
$custom-file-button-bg: $gray-200;
$custom-file-text: (placeholder: (en: 'Choose file...'), button-label: (en: 'Browse'));
$form-feedback-valid-color: theme-color('success');
$form-feedback-invalid-color: theme-color('danger');

// Navbar
$navbar-padding-y: $spacer/2;
$navbar-padding-x: $spacer;